export type RegionCodes = "SK";
//export type RegionCodes = "AT-07" | "IT-32-BZ" | "IT-32-TN" | "SK";

export const regionCodes: readonly RegionCodes[] = Object.freeze([
  // "AT-07",
  // "IT-32-BZ",
  // "IT-32-TN",
  "SK"
]);

export const regionsRegex = /^(SK)/;
//export const regionsRegex = /^(AT-07|IT-32-BZ|IT-32-TN|SK)/;
